<script setup>
import { defineEmits, onMounted, onUpdated, ref } from "vue";
import { KNOLIA } from "../knolia";
import { Gems } from "@/gems";
import { useTreeStore } from '@/store/modules/trees.js';
import Element from './DepthElement.vue';
import TreeType from "../enums/TreeType";
import { MESSAGES } from "../messages";

// eslint-disable-next-line no-undef
let props = defineProps(["column", "meta_id", "is_knowledge"]);
let emit = defineEmits(["filter-children"]);
let inputLvlName = ref();
let showOptions = ref(false);
const store = useTreeStore();

function ChangeColumnName(column) {
  store.UpdateDepthName({
    name: inputLvlName.value.value,
    element: column,
    update: "name",
    treeType: TreeType.SKILLS,
  })
  showOptions.value = false
}

function DefineAsKnowledge(column) {
  column.is_knowledge = !column.is_knowledge

  store.SelectedMeta.levels.forEach(level => {
      if(level.id != column.id)
        level.is_knowledge = false
    })

  KNOLIA.CallBackEnd("post", "control-tree-element", {
    update: "knowledge",
    depth: column,
    treeType: TreeType.SKILLS,
  }).then((res) => {
    if (res.error) {
      Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
      return
    }

    Gems.Telegram(MESSAGES.SUCCESS.CHANGED, "tele-success")

    store.Tree.forEach(meta => {
      if(meta.id == 1)
        meta.levels[0].elements = res.data.knowledge_elements
    })
  })
}

function DeleteColumn(column) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_COLUMN_TREE, "tele-info", "", "tele-cancel").then(
    () => {
      KNOLIA.CallBackEnd("post", "delete-column", {
        column: column,
        tree: "skills",
      }).then((res) => {
        if (res.error) {
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
        } else {
          Gems.Telegram(MESSAGES.SUCCESS.REMOVED, "tele-success")
          store.UpdateSkillsTree()
        }
      });
    },
    () => {}
  );
}

function ShowOptions() {
  if(props.column.depth > props.column.totalDepth - 2 && !props.is_knowledge)
    return

  showOptions.value = true
}

onMounted(() => {
  Object.assign(props.column, { is_knowledge: KNOLIA.ToBoolean(props.column.is_knowledge)})
  store.setColumnsTooltip(props.column)
});

onUpdated(() => {
  store.setColumnsTooltip(props.column)
});
</script>

<template>
  <div class="depth-container">
    <div class="depth-header-skills-tree">
      <div class="div-column-icons">
        <i class="fa fa-plus-circle i-add-meta" :id="`new-column${column.id}`"
          @click="store.NewColumn({meta_id: column.meta_id, depth: column.depth, totalDepth: column.totalDepth, elements: column.elements, treeType: TreeType.SKILLS})"
          v-show="!props.is_knowledge && column.depth <= column.totalDepth - 1"></i>
          
          <i class="fa fa-trash cursor-pointer" :id="`remove-column${column.id}`" @click="DeleteColumn(column)" v-show="!props.is_knowledge && column.depth <= column.totalDepth - 2"></i>
          
          <i class="fa-bookmark cursor-pointer" :class="{far: !column.is_knowledge, fas: column.is_knowledge}" v-show="column.depth <= column.totalDepth - 2"
            :id="`knowledge${column.id}`" @click="DefineAsKnowledge(column)" ></i>        
      </div>

      <div class="depth" @click="store.showAllElements({depth: column.depth, meta_id: column.meta_id})"
        :id="`depth${column.depth}`">
        Nível {{ column.depth }}
      </div>
    </div>
    <div class="depth-second-header">
      <input ref="inputLvlName"
        :class="!props.is_knowledge && column.depth <= column.totalDepth - 2 ? 'col-name' : 'col-name depth-name'"
        type="text"
        :disabled="column.depth > column.totalDepth - 2 && !props.is_knowledge"
        :readonly="!showOptions"
        :value="column.designation"
        @dblclick="ShowOptions()"
        placeholder="[Nome do nível]"
      />
      <div class="div-menu-edit" v-show="showOptions">
        <i class="fa fa-check confirm" :id="`confirm-column-name-icon-${column.id}`" @click="ChangeColumnName(column)"></i>
        <i class="fa fa-times cancel" :id="`cancel-column-name-icon-${column.id}`" @click="showOptions = false"></i>
      </div>
    </div>
  </div>
  <div class="elements-container scrollbar">
    <span v-for="(element, index) in column.elements" :key="index" class="meta-comp-item" :class="{show: element.showSelf == true || column.depth == 1}">
      <Element :element="element"
        :parent="column.id"
        :column-depth="column.totalDepth"
        :meta_id="column.meta_id"
        :depth="column"
        :index="index"
        :tree-type="TreeType.SKILLS"
        v-if="element.showSelf == true || props.column.depth == 1"
        @filter-children="emit('filter-children', $event)"
      />
    </span>
  </div>
</template>

<style scoped>
@import './assets/styles/trees-style.css';

.depth {
  padding-right: 31%;
}
</style>
