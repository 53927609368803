<script setup>
import { useTreeStore } from '@/store/modules/trees.js';
import DepthTasksContainer from "@/components/DepthTasksContainer";
import DepthContainer from "@/components/DepthContainer";

// eslint-disable-next-line no-undef
let props = defineProps(["metaType"]);

const store = useTreeStore();

function FilterChildren(elem) {
  store.FilterChildren(elem);
}
</script>

<template>
  <div class="main" id="main" v-if="props.metaType != 'tasks'">
    <section class="meta-comp-section" :class="{'special-container': column.depth > column.totalDepth - 2}" 
      v-for="(column, index) in store.SelectedMeta.levels" :key="index">
      <DepthContainer :column="column" :meta_id="store.SelectedMeta.elem_id"
        :is_knowledge="store.SelectedMeta.designation == 'Instrumentais' ? true : false"
      />
    </section>
  </div>
  <div class="main" id="main" v-else-if="props.metaType == 'tasks'">
    <section class="meta-comp-section" :class="{'special-container': column.depth > column.totalDepth - 2}"
      v-for="(column, index) in store.SelectedMeta.levels" :key="index">
      <DepthTasksContainer :column="column" :meta_id="store.SelectedMeta.elem_id" @filter-children="FilterChildren($event)" />
    </section>
  </div>
</template>

<style scoped>

.main {
  display: flex;
}

.meta-comp-section {
  padding: 5px 2px;
  max-width: 250px;
  width: 250px;
  height: 100%;
}

.special-container {
  background-color: #0000002e;
  margin: 0px 2px;
  border-radius: 4px;
  padding: 5px;
}
</style>
