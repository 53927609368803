<script setup>
// eslint-disable-next-line no-unused-vars
import { useRouter } from "vue-router"
import { ref, onMounted, onUpdated } from "vue"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { MESSAGES } from "../messages";

// eslint-disable-next-line no-undef
let emit = defineEmits(["force-reload"]);
let file = ref(null);
let name = ref(null);
let src = ref(String);
let preview = ref(false);
let fileRef = ref(null);
let logoRef = ref(null);
let router = useRouter();

function SelectFile(e) {
  file.value = e.target.files[0]
  name.value = KNOLIA.Cyrb53(`${Date.now()}`, 5)
  src.value = URL.createObjectURL(file.value)
  preview.value = true
}

async function UpdateData() {
  const formData = new FormData()
  formData.append("photo", fileRef.value.files[0])
  formData.append("name", name.value)
  formData.append("admin", "true")
  
  const res = await KNOLIA.CallBackEnd("post", "set-operations&get=set-logo", formData)

  if(res.error) {
    Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-alowed')
    return
  }

  Gems.Telegram(MESSAGES.SUCCESS.CHANGED, 'tele-success')
  fileRef.value = null
  file.value = null
  name.value = null
  preview.value = false
  emit("force-reload");
}

onMounted(() => {
  if (logoRef.value)
    Gems.Tooltip(logoRef.value, "Logotipo da empresa", "tooltip")
});

onUpdated(() => {
  if (logoRef.value) 
    Gems.Tooltip(logoRef.value, "Logotipo da empresa", "tooltip")
});
</script>

<template>
  <div class="div-admin" v-if="router.currentRoute._value.path === '/admin'">
    <input class="inputfile" id="file" type="file" ref="fileRef" @change="SelectFile"/>
    <label for="file" class="import-logo" ref="logoRef">
      <img :src="src" v-if="preview == true" />
      <i class="fab fa-linode fa-4x" aria-hidden="true" v-show="preview == false"></i>
    </label>

    <button class="logo-btn" @click="UpdateData" ref="button" v-show="preview == true">CARREGAR LOGOTIPO</button>

    <div class="buttons-admin">
      <button @click="router.push({ name: 'Import' })">IMPORTAR / EXPORTAR</button>
      <button @click="router.push({ name: 'SkillsTree' })">GERIR COMPETÊNCIAS</button>
      <button @click="router.push({ name: 'TasksTree' })">GERIR ATIVIDADES E TAREFAS</button>
      <button @click="router.push({ name: 'JobTasks' })">GERIR FUNÇÕES E ATIVIDADES</button>
      <button @click="router.push({ name: 'Permissions' })">PERMISSÕES</button>
    </div>
  </div>
  <router-view></router-view>
</template>

<style scoped>

.div-admin {
  margin: 16px auto 16px auto;
  flex-direction: column;
  display: flex;
  width: 900px;
}

.div-admin button {
  width: 300px;
  height: 50px;
  margin: 10px;
}

.buttons-admin {
  margin-left: 130px;
}

.import-logo {
  height: 165px;
  width: 300px;
  margin: 10px auto 25px auto;
  border: 1px solid;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.import-logo img {
  max-width: 100%;
}

.logo-btn {
  margin: 0 auto !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.125em;
  cursor: pointer;
}
</style>
