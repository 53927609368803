<script setup>
import { onUpdated } from "vue"
import { useTreeStore } from '@/store/modules/trees.js';
import { Gems } from "@/gems"
import { KNOLIA } from "../knolia";

const store = useTreeStore()

function Drag(event, element, type) {
  if (type == "skill") {
    element.fromSkillsList = true;
  } else {
    element.fromSkillsOpList = true;
  }
  event.dataTransfer.setData("element", JSON.stringify(element));
}

async function RemoveFromTree(elem) {
  const res = await KNOLIA.CallBackEnd("post", "remove-skill-from-task", {
    element: elem
  })

  if(res.error)
    Gems.Telegram('Ocorreu um erro', 'tele-not-allowed')

  store.UpdateTasksTree()

}

onUpdated(() => {
  for (let element of store.SkillsArr) {
    // const sitemap = document.getElementById(`skill${element.skill_id}`)
    const deleteIcon = document.getElementById(`delete-skill${element.skill_id}`)
    
    // if (sitemap) 
    //   Gems.Tooltip(sitemap, element.sitemap, "tooltip")
  
    if(deleteIcon && element.included)
      Gems.Tooltip(deleteIcon, "Dissociar Competência", "tooltip")

    for (let child of element.children) {
      const childSitemap = document.getElementById(`skill-op${child.id}`)
      const deleteIcon = document.getElementById(`delete-skill-op${child.id}`)

      if (childSitemap) 
        Gems.Tooltip(childSitemap, child.sitemap == null ? 'Não atribuído' : child.sitemap, "tooltip tooltip-left")

      if(deleteIcon && element.included)
        Gems.Tooltip(deleteIcon, "Remover da árvore", "tooltip")
    }
  }
});
</script>

<template>
  <section class="skill-list-section ">
    <div class="skill-container">
      <div>
        <div class="div-skills-header">
          <span>Competências</span>
        </div>

        <div class="div-skills-list" v-for="(elem, i) in store.SkillsArr" :style="elem.visible" :key="i">

          <div draggable="true" @dragstart="Drag($event, elem, 'skill')" class="skill default-radius bg-alt-line" >

            <div>
              <i class="fa fa-share fa-flip-horizontal remove-icon" :id="`delete-skill${elem.skill_id}`" v-if="elem.included" @click="RemoveFromTree(elem)"
              ></i>
              
              <span :id="`skill${elem.skill_id}`" >
                {{ elem.designation }}
              </span>
            </div>

            <i class="fa cursor-pointer show-childs" :class="{'fa-chevron-down': !elem.showChildren , 'fa-chevron-up': elem.showChildren}"
              @click="elem.showChildren = !elem.showChildren"></i>
          </div>
          
          <div class="div-line-skills-op-list" v-show="elem.showChildren">
            <div v-for="(child, idx) in elem.children" :key="idx" draggable="true" @dragstart="Drag($event, child, 'skill_op')">
              <i class="fa fa-share fa-flip-horizontal remove-icon" :id="`delete-skill-op${child.id}`" v-if="child.included" @click="RemoveFromTree(child)"
              ></i>
              
              <span :id="`skill-op${child.id}`">
                {{ child.designation }}
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<style scoped>

.skill-list-section {
  text-align: center;
  max-width: max-content;
  min-width: 500px;
}


.div-skills-header {
  display: flex;
}

.div-skills-header span {
  flex: 1;
  font-weight: bold;
}

.div-line-skills-op-list {
  padding-left: 15px;
}

.div-line-skills-op-list div {
  margin-bottom: 5px;
}

.div-skills-list div {
  text-align: start;
}

.skill-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-icon {
  font-size: 0.8em;
  margin: 0 5px 0 5px;
}

.skill {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
}

.show-childs {
  height: 10px;
}
</style>
