<!-- eslint-disable no-undef -->
<script setup>
import { onMounted, onUpdated, ref } from "vue"
import { useTreeStore } from '@/store/modules/trees.js';
import { KNOLIA } from "../knolia"
import { Gems } from "../gems"
import TreeType from "@/enums/TreeType"
import { MESSAGES } from "../messages"


const props = defineProps(["element", "parent", "meta_id", "column-depth", "is_meta", "depth", "index", 'tree-type'])
const store = useTreeStore()
const showChilds = ref(false)
let inputNameRef = ref()
let showOptions = ref(false)

function ChangeMetaOrElement() {
  Object.assign(props.element, {selected: !props.element.selected})
  
  if (props.is_meta == true) 
    store.setSelectedMeta(props.element.selected ? props.element : [])
  else
    store.FilterChildren([props.element, props.element.selected, props.meta_id])
}

async function Drop(event, element) {
  element.selected = true
  element.has_children++ 
    if(props.treeType == TreeType.SKILLS)
        store.DropElementInSkillsTree(event, element)
    else
        store.DropElementInTasksTree(event, element)
}

function OptionsHandler(status) {
  if (!status) {
    inputNameRef.value.setAttribute("readonly", !status);
    inputNameRef.value.classList.add("pointer");
  } else {
    inputNameRef.value.removeAttribute("readonly");
    inputNameRef.value.classList.remove("pointer");
  }
  showOptions.value = status;
}

function ChangeInput(element) {
  if (element.is_activity || element.is_task)
    return

  if(element.new)
    element.designation = ''

  OptionsHandler(true)
}

function isKnowledge(elem) {
  return elem.meta_comp && elem.elem_id == 1
}

function ElementConfirm() {
  OptionsHandler(false)
  store.UpdateElementName({
    name: inputNameRef.value.value,
    update: props.is_meta ? "meta" : "element",
    treeType: props.treeType,
    element: props.element
  })
}

function ElementCancel() {
  if (props.element.new) {
    store.removeElementAndChilds(props.element)
  } else {
    OptionsHandler(false);
    inputNameRef.value = props.element.description;
  }
}

async function RemoveOpSkill(element) {
  if (element.is_op_skill) {
    await KNOLIA.CallBackEnd("post", "remove-skill-from-task", {
      element: element,
    })
    
    store.UpdateTasksTree()
  }  
}

function Remove(element) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_FROM_TREE, "tele-info", "", "tele-cancel")
    .then(
      async () => {
        if (element.is_activity || element.is_skill)
          element.relational_id = element.id

          await store.DissociateElement(element, props.treeType)

          if(props.is_meta && props.meta_id == element.meta_id) 
            store.setSelectedMeta([])
      },
      () => {}
    );
}

async function CreateElem() {
  if(!props.element.selected)
    Object.assign(props.element, {selected: !props.element.selected})

  Object.assign(props.element, {has_children: props.element.has_children + 1})

  if (props.is_meta == true)
    if(props.element.levels.length == 2)
      await store.NewColumn({meta: props.element, meta_id: props.element.meta_id, depth: parseInt(props.element.depth, 10) + 1, elements: [], treeType: props.treeType})

  KNOLIA.NewElem(store.insertChild, props.element)
  
  if (props.is_meta == true) 
    store.setSelectedMeta(props.element)
  else
    store.FilterChildren([props.element, true, props.meta_id])
}

function SetElementRowClass(element) {
    let openedRow = ''
    let rowTask = ''
    let draggable = ''

    if(element.selected || showChilds.value)
        openedRow = 'opened-row'

    if(element.is_task)
        rowTask = 'row-task'

    if(element.is_draggable)
        rowTask = 'draggable'

    return `element-row default-radius ${openedRow} ${rowTask} ${draggable}`
}

onMounted(() => {
  Object.assign(props.element, { selected: KNOLIA.ToBoolean(props.element.selected) })
  Object.assign(props.element, { is_op_skill: KNOLIA.ToBoolean(props.element.is_op_skill) })
  Object.assign(props.element, { is_draggable: KNOLIA.IsDraggable(props.element, props.columnDepth, showOptions.value) })
  Object.assign(props.element, { index: props.index})
  inputNameRef.value.value = props.element.designation
  store.setElementsTooltips(props.element)
})

onUpdated(() => {
  store.setElementsTooltips(props.element)
})
</script>

<template>
  <div class="div-depth-element">

    <span v-if="props.element.parent_designation" class="parent-name" :id="`parent-name${props.element.id}`">{{ props.element.parent_designation }}</span>

    <div :class="SetElementRowClass(props.element)" :id="`${props.element.designation + props.element.id}`"
        :draggable="props.element.is_draggable"
        @dragstart="KNOLIA.Drag($event, props.element)"
        @dragover.prevent
        @drop="Drop($event, props.element)"
    >
    {{ element.id }}
      
      <i class="fas cursor-pointer show-childs" :class="{'fa-plus': !element.selected, 'fa-minus': element.selected}" :id="`checkbox${props.element.id}`"
          v-if="(!props.element.is_task && !props.element.is_op_skill && props.element.has_children > 0) || props.is_meta"
          @click="ChangeMetaOrElement"
      ></i>
      <div v-else-if="!props.element.is_task && !props.element.is_op_skill" class="checkbox-div"></div>

      <input readonly="true" ref="inputNameRef" class="input" :value="element.designation" :title="props.element.designation" @dblclick="ChangeInput(element)" />

      <div class="div-menu-edit" v-show="showOptions || props.element.new">
        <i class="fa fa-check confirm" :id="`confirm-elem-name${element.id}`" @click="ElementConfirm"></i>
        <i class="fa fa-times cancel" :id="`cancel-elem-name${element.id}`" @click="ElementCancel"></i>
      </div>

      <div class="element-std-icons">
        <i class="fa fa-plus-circle i-add-elem" :id="`new-elem${props.element.id}`"
          v-show=" !showOptions && !props.element.new && !isKnowledge(element) && (props.element.depth < props.element.totalDepth - 2 || props.is_meta)"
          @click="CreateElem"
        ></i>
  
        <i class="fa fa-trash remove-icon" :id="`delete${props.element.id}`"
          v-if="!props.element.is_task && !props.element.new && !props.element.is_op_skill && !isKnowledge(element)"
          v-show="!showOptions && !props.element.new" @click="Remove(props.element)"
        ></i>
  
        <i class="fa fa-list list-icon" :id="`skills-op${props.element.skill_id}`"
          v-if="props.element.is_task && !props.element.new && props.element.children.length > 0" @click="showChilds = !showChilds"
          ></i>
      </div>
    </div>

    <div v-show="props.element.is_task && showChilds" class="element-children-list" :id="`div-skills-op${props.element.id}`">
      <div v-for="child of element.children" :key="child.id">
        <div style="display: flex; justify-content: space-between;">
          <span>{{ child["designation"] }}</span>
          <i class="fas fa-times-circle cursor-pointer" :id="`delete${props.element.id}`" @click="RemoveOpSkill(child)"></i>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
  @import './assets/styles/trees-style.css';

</style>
