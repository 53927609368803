<script setup>
import { onMounted } from "vue"
import { ref } from "@vue/reactivity"
import Menu from "@/components/Menu"
import router from "./router"
import { KNOLIA } from "./knolia";

const reload = ref(false)
const reloadMenu = ref(false)

// function Reload() {
//   router.currentRoute.value.params.id = null
//   router.currentRoute.value.params.userId = null
//   reload.value = !reload.value
// }

onMounted(() => {
  KNOLIA.CheckCredentials(router.currentRoute.value.meta.requiresAuth);
})
</script>

<template>
  <div class="app-container">
    <div class="div-master" id="div-master" v-if="router.currentRoute.value.path != '/privacy'">
      <Menu :key="reloadMenu"/>
      <div class="div-content">
        <router-view :key="reload" @force-reload="reloadMenu = !reloadMenu" />
      </div>
    </div>

    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<style scoped>
.app-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(160deg, #002e61, #2361C2);
}

.div-master {
  min-height: 100vh;
  display: flex;
  height: fit-content;
}

.div-content {
  width: 83%;
  z-index: 0;
  overflow: hidden;
  min-height: -webkit-fill-available;
}

</style>
