<script setup>
import { onMounted, onUpdated, ref } from "vue";
import { useTreeStore } from '@/store/modules/trees.js';
import { Gems } from "@/gems";

const store = useTreeStore();
let searchText = ref("");

function Drag(event, element) {
  element.fromElementList = true;
  event.dataTransfer.setData("element", JSON.stringify(element));
}

function SearchSkills() {
  const arrFiltered = store.SkillsArr.filter(
    (elem) =>
      elem.designation
        .trim()
        .toUpperCase()
        .search(searchText.value.trim().toUpperCase()) > -1
  );

  store.SkillsArr.forEach(function (indicator) {
    indicator.visible = "display: none";
    arrFiltered.forEach(function (filtered) {
      if (indicator.skill_id == filtered.skill_id) {
        indicator.visible = "";
      }
    });
  });
}

function RemoveSkillFromTree(skill) {
  document.getElementById(`delete${skill.tree_id}`).click()
}

onMounted(() => {
  store.GetSkills();
});

onUpdated(() => {
  for (let skill of store.SkillsArr) {
    const sitemap = document.getElementById(`skill${skill.skill_id}`)

    if(sitemap && skill.sitemap)
      Gems.Tooltip(sitemap, skill.sitemap , "tooltip tooltip-left")

    if (skill.included) {
      const deleteIcon = document.getElementById(`delete${skill.skill_id}`)

      if(deleteIcon)
        Gems.Tooltip(deleteIcon, "Remover da árvore", "tooltip")
    }
  }
});
</script>

<template>
  <section class="skill-list-section">
    <div class="skill-title">
      <span>Competências</span>
    </div>
    <div class="skill-container">
      <div class="filter">
        <input type="text" v-model="searchText" placeholder="Filtrar..." @keyup="SearchSkills" />
      </div>
      <div v-for="(skill, i) in store.SkillsArr" :key="i" :draggable="true" @dragstart="Drag($event, skill)">
        <div class="skill-line bg-alt-line default-radius" :style="skill.visible">
          <div class="div-icon">
            <i class="fa fa-share fa-flip-horizontal remove-icon" :id="`delete${skill.skill_id}`"
              v-if="skill.included ? true : false" @click="RemoveSkillFromTree(skill)"></i>
          </div>
          
          <div class="skill" :id="`skill${skill.skill_id}`">
            <span class="line-clamp line-clamp-1">{{ skill.designation }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.skill-list-section {
  text-align: center;
  padding: 15px;
  max-width: 450px;
  min-width: 300px;
}

.skill-title {
  padding-bottom: 13px;
}

.filter,
.skill-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.skill {
  text-align: center;
  padding: 2px 10px;
  width: 95%;
  margin-left: -5px;
  cursor:grab;
}

.filter {
  margin-bottom: 20px;
}

.filter input {
  width: 100%;
  flex: 3;
}

.filter input::placeholder {
  color: var(--default-font-color);
}


.div-icon {
  width: 5%;
  font-size: 0.8em;
}
</style>