<script setup>
import { onMounted } from "vue";
import { useTreeStore } from '@/store/modules/trees.js';
import { KNOLIA } from "@/knolia";
import DepthElement from "@/components/DepthElement";
import MetaContainer from "@/components/MetaContainer";
import SkillList from "@/components/SkillList";
import { onBeforeRouteLeave } from "vue-router";
import { Gems } from "@/gems";
import TreeType from "../enums/TreeType";

const store = useTreeStore();

async function NewMeta() {
  await KNOLIA.CallBackEnd("post", "control-tree-element", {
    update: "meta",
    treeType: TreeType.SKILLS,
    name: "[Novo Elemento]",
  })

  store.UpdateSkillsTree();
}

onMounted(() => {
  store.GetDepth();
  Gems.Tooltip(document.getElementById("newMeta"), "Novo elemento", "tooltip");

  KNOLIA.ResizeSplitScreen(document.getElementById("separator"), "V");
});

onBeforeRouteLeave(() => {
  store.resetTree();
});
</script>

<template>
  <div class="splitter page-container">
    <div id="first" class="top">
      <div class="scrollbar skills-list">
        <SkillList />
      </div>
    </div>
    <div id="separator" class="separator"></div>
    <div id="second" class="bottom scrollbar">
      <div class="table-container">
        <div>
          <section class="meta-comp-section">
            <div>
              <span class="meta-title">META AGRUPAMENTOS</span>
              <i id="newMeta" class="fa fa-plus-circle i-add-elem" @click="NewMeta"></i>
            </div>
            <div class="meta-comp-container">
              <span v-for="(column, index) in store.Tree" :key="index" class="meta-comp-item">
                <DepthElement
                  :element="column"
                  :parent="column.id"
                  :meta_id="column.elem_id"
                  :is_meta="true"
                />
              </span>
            </div>
          </section>
        </div>
        <MetaContainer />
      </div>
    </div>
  </div>
</template>

<style scoped>

.table-container {
  max-width: 80vw;
  display: flex;
  margin-top: 10px;
}

.meta-comp-container {
  display: flex;
  flex-direction: column;
}

.meta-comp-container span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meta-comp-item {
  text-align: center;
  margin-bottom: 1px;
}

.meta-comp-section {
  padding: 1px 2px;
  width: 250px;
  min-height: 100%;
  text-align: center;
  margin-top: 55px;
}

.i-add-elem {
  margin-left: 10px;
  cursor: pointer;
}

.meta-title {
  color: white;
}

.skills-list {
  width: 100%;
  margin-bottom: 10px;
}
</style>
