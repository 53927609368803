import axios from "axios";
import { MESSAGES } from "./messages";
import { Gems } from './gems'
import router from '@/router'

class KNOLIA {
  static ERROR_MESSAGE_GENERIC = "Contacte o administrador";

  static CheckCredentials() {
    document.addEventListener(
      "visibilitychange",
      async function () {
        if (!document.hidden && router.currentRoute.value.meta.requiresAuth == true) {
          await KNOLIA.CallBackEnd("get", "check-credentials");
        }
      },
      false
    );
  }

  static GetLoggedUserId() {
    return sessionStorage.getItem("logged_user_id")
  }

  static GetRequestedUserId() {
    return sessionStorage.getItem("requested_user_id") ?? KNOLIA.GetLoggedUserId()
  }

  static SetRequestedUserId(userId) {
    return sessionStorage.setItem("requested_user_id", userId)
  }

  static GetDefaultPhoto() {
    return window.parent.location.hostname.match("localhost") ? 'src/images/default.png' : '../images/default.png'
  }

  static async CallBackEnd(method, action, params) {
    const loggedUserId = KNOLIA.GetLoggedUserId()
    const requestedUserId = KNOLIA.GetRequestedUserId()

    let src = `../php/transaction.php?action=${action}`;

    if (loggedUserId && !Number.isNaN(Number(loggedUserId)))
      src += `&logged_user_id=${loggedUserId}`;

    if (requestedUserId && !Number.isNaN(Number(requestedUserId)))
      src += `&requested_user_id=${requestedUserId}`;

    let response = await axios[method](src, params).then((res) => {

      if (res.data != null) {
        if (res?.data?.logged_user_id)
          sessionStorage.setItem("logged_user_id", res.data.logged_user_id);

        res.data = res.data.response;
        KNOLIA.StatusHandler(res.data);
      }

      return res;
    }).catch(() => { Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed') });
    return response;
  }

  static StatusHandler(response) {
    let url = "";

    switch (response?.status) {
      case 0:
        if (window.parent.location.pathname != "/")
          window.location.href = `${Gems.HubUrl()}/logout?message=Sessão expirada`

        break;
      case 1:
        window.parent.location.href = `home`;
        break;

      case 2:
      case 3:
        url = `${window.parent.location.origin}/home`;

        window.parent.history.pushState({ change: true, message: `LOGIN_CHANGED` }, "", url)
        window.parent.history.go()
        break;

      case 4:
        window.location.href = `${Gems.HubUrl()}/logout?message=Sessão expirada`
        break;
    }
    return;
  }

  static CovertNumToBool(arr, columnName) {
    for (let elem in arr) {
      if (arr[elem][columnName] == 0) {
        arr[elem][columnName] = false;
      } else {
        arr[elem][columnName] = true;
      }
    }
    return arr;
  }

  static Cyrb53 = function (str, seed) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  static ShowChildrenOnList(div, element, createIcon, deleteFunc) {
    const divWarning = document.getElementById(div)

    if (divWarning.children.length > 0) {
      divWarning.removeChild(divWarning.lastElementChild)
      return
    }

    const divContainer = document.createElement("div")
    divWarning.appendChild(divContainer)
    for (let child of element.children) {
      const li = document.createElement("li")
      const span = document.createElement("span")
      span.innerHTML = child["designation"]
      li.appendChild(span)

      if (createIcon) {
        const icon = document.createElement("i")
        icon.className = "fa fa-times delete-skills-op"
        icon.id = "delete"
        icon.element = child
        li.appendChild(icon)
        icon.addEventListener("click", (ev) => {
          deleteFunc(ev.target.element)
        })
      }

      divContainer.appendChild(li)
    }
  }

  static ResizeSplitScreen(element, direction) {
    var md; // remember mouse down info
    const first = document.getElementById("first");
    const second = document.getElementById("second");

    element.onmousedown = onMouseDown;

    function onMouseDown(e) {
      md = {
        e,
        offsetLeft: element.offsetLeft,
        offsetTop: element.offsetTop,
        firstHeight: first.offsetHeight,
        secondHeight: second.offsetHeight,
      };

      document.onmousemove = onMouseMove;
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
      };
    }

    function onMouseMove(e) {
      var delta = {
        x: e.clientX - md.e.clientX,
        y: e.clientY - md.e.clientY,
      };

      if (direction === "V") {
        // Vertical
        // Prevent negative-sized elements
        delta.x = Math.min(Math.max(delta.y, -md.firstHeight), md.secondHeight);

        element.style.top = md.offsetTop + delta.x + "px";
        first.style.height = md.firstHeight + delta.x + "px";
        second.style.height = md.secondHeight - delta.x + "px";
      }
    }
  }

  static IsDraggable(elem, totalDepth, editMode) {
    if (elem.depth < totalDepth - 1 && !editMode) {
      return true;
    }
    return false;
  }

  static Drag(event, element) {
    let checkDepth = element.totalDepth > 2 ? element.totalDepth - 2 : 1;
    if (element.depth <= checkDepth) {
      event.dataTransfer.setData("element", JSON.stringify(element));
      return;
    }
  }

  static NewElem(call, element) {
    let newElem = {
      designation: "[Novo elemento]",
      showSelf: true,
      parent_id: element.id,
      parent_designation: element.designation,
      selected: false,
      totalDepth: element.totalDepth,
      depth: parseInt(element.depth, 10) + 1,
      new: true,
      meta_id: element.meta_id,
      elem_id: null,
      has_children: 0,
      id: `TEMP-${element.id}-${element.has_children}`
    };

    call(newElem);
  }

  static isAdmin() {
    return JSON.parse(localStorage.getItem("admin")).value == 1;
  }

  static ToBoolean(num) {
    return !!Number(num)
  }

  static async LogOut() {
    await KNOLIA.CallBackEnd("post", "logout").then(() => {
      localStorage.setItem("auth", JSON.stringify({ value: false }))
      sessionStorage.removeItem("logged_user_id");
      window.location.href = `${Gems.HubUrl()}/logout`
    });
  }
}

export { KNOLIA };
