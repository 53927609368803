<script setup>
import { onMounted } from "vue";
import { MESSAGES } from "../messages";
import { Gems } from "../gems";

onMounted(() => {
  if(history.state?.change) {
      Gems.Telegram(MESSAGES.SUCCESS[history.state.message], 'tele-success')
  }
})

</script>

<template>
  <div class="page-container home">
    <div class="container-logo">
      <div class="logo">
        <span class="knolia">KNOLIA</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home {
  width: 100%;
  min-height: 96vh;
  display: flex;
  position: relative;
}

.container-logo {
  width: 550px;
  height: 550px;
  position: absolute;
  display: flex;
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.knolia {
  font-size: 4em;
  font-weight: bold;
  position: absolute;
  left: 71%;
  top: 93%;
  animation: knolia 4000ms;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1;
}

.logo {
  background-image: url(../images/asterisco.png);
  background-repeat: no-repeat;
  background-size: 550px;
  width: 100%;
  height: 100%;
  animation: white-circle 2000ms ease-out, image 2000ms ease-in;
  animation-delay: 0s, 2s;
  animation-iteration-count: 1;
  border-radius: 50%;
  margin: auto;
}

@keyframes white-circle {
  0% {
    opacity: 0;
    background: white;
    width: 5%;
    height: 5%;
    margin: auto;
  }

  50% {
    opacity: 1;
    background: white;
    width: 5%;
    height: 5%;
    margin: auto;
  }
  

  100% {
    opacity: 0;
    background: white;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

@keyframes image {
  0% {
    opacity: 0;
    background-image: url(../images/asterisco.png);
  }

  100% {
    opacity: 1;
    background-image: url(../images/asterisco.png);
  }
}

@keyframes knolia {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
    left: 85%;

  }

  100% {
    opacity: 1;
    left: 71%;    

  }
}
</style>
