<script setup>
import { onMounted } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { defineEmits } from "vue"
import { MESSAGES } from "../messages"

const defaultImage = ref(require('@/images/default.png'))

defineEmits(['force-reload'])

const arrUsers = ref([])
const abortController = ref(null)
const txtUser = ref('')
const reload = ref(false)

async function GetUsers() {
    const res = await KNOLIA.CallBackEnd("get", "get-users-permissions")

    if (res.error) {
        Gems.Telegram(MESSAGES.ERROR.DEFAULT, 'tele-not-allowed')
        return
    }

    FormatArrUsers(res.data.users)
}

function FormatArrUsers(users) {
    users.forEach(user => {
        user.can_search_users = Boolean(user.can_search_users)
    })

    arrUsers.value = users
}

async function SearchUsers() {
    if (txtUser.value == "") {
        GetUsers()
        return
    }

    if (abortController.value != null)
        abortController.value.abort();

    abortController.value = new AbortController();

    const res = await KNOLIA.CallBackEnd("get", "get-users-permissions", {
        params: {
            search_text: txtUser.value,
            signal: abortController.value.signal,
        },
    })

    FormatArrUsers(res.data.users)
}

async function UpdateUserPermissions(user) {
    await KNOLIA.CallBackEnd("post", "update-user-permissions", {
        params: {
            user
        },
    })
}

onMounted(() => {
    GetUsers()
})
</script>

<template>
    <div class="page-container">
        <div class="div-search-users">
            <input type="text" class="input-user" placeholder="Pesquisar colaborador..." v-model="txtUser"
                @keyup="SearchUsers()">
        </div>

        <div class="row header">
            <div></div>
            <div>Nome</div>
            <div>Função</div>
            <div>Categoria</div>
            <div class="div-search-people">Procurar Pessoas</div>
        </div>

        <div class="users-list" :key="reload">
            <div class="row" v-for="(user, index) in arrUsers" :key="user.id" :class="{ 'bg-alt-line': index % 2 == 0 }">
                <img ref="div-logo" class="user-photo" :src="user.src" @error="$event.target.src = defaultImage">
                <span class="user-name">{{ user.name }}</span>
                <span>{{ user.job }}</span>
                <span>{{ user.category }}</span>
                <input type="checkbox" v-model="user.can_search_users" @change="UpdateUserPermissions(user)" class="cursor-pointer">
            </div>
        </div>
    </div>
</template>

<style scoped>

.div-search-users {
    display: flex;
    margin-bottom: 1%;
}

.div-inactives-checkbox {
    display: flex;
    align-self: center;
    margin-left: 10px;
}

.chk {
    transform: scale(1.5);
    margin-right: 10px;
}

.input-user {
    flex: 1;
    font-style: italic;
}

.header {
    font-weight: 600;
}

.row {
    padding: 5px;
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 3% 30% 29% 29% 9%;
    align-items: center;
    border-radius: 5px;
}

.user-name {
    font-weight: bold;
    cursor: pointer;
    margin-left: 5px;
}

.div-search-people {
    text-align: center;
}


.user-photo {
    height: 30px;
    width: 31px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 10px 1px rgb(0, 0, 0, 0.1);
    margin: 0 auto;
    object-fit: cover;
}
</style>
