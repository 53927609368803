<script setup>
import { onMounted } from "vue"
import { ref } from "@vue/reactivity"
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import router from "../router"
import { MESSAGES } from "../messages"

const userId = ref(null)
const photo = ref(null)
const accept = ref(null)

async function Accept() {
  if (accept.value !== true) {
    Gems.Telegram(MESSAGES.ERROR.PRIVACY, "tele-info", "")
    return
  }
  
  if (photo.value)
    photo.value = 1
  else 
    photo.value = 0

  await KNOLIA.CallBackEnd("post", "policy-control", {
    photo: photo.value,
    user_id: userId.value,
  })
  router.push({ name: "Home" })
}

async function GetPolicy() {
  await KNOLIA.CallBackEnd("get", "policy-control").then((res) => {
    if (res.data.length > 1) {
      accept.value = true
      photo.value = res.data[0]
      userId.value = res.data[1]
    } else {
      photo.value = false
      userId.value = res.data[0]
    }
  });
}

onMounted(() => {
  GetPolicy()
})

</script>



<template>
  <div class="page">

    <div class="container">
      <div class="policy">
        <h1 class="title">INFORMAÇÃO SOBRE O SISTEMA E PRIVACIDADE</h1>
        <p>
          Os sistemas CIRVALUE são sistemas de avaliação contínua do desempenho,
          de competências e comportamentos profissionais e/ou 360 graus, que
          potenciam o feedback interpessoal, a análise colaborativa e gestão
          contínua do desempenho.
        </p>
        <p>
          A sua Empresa adotou este Sistema numa destas ou em diversas vertentes
          das referidas, com o fim de orientar, monitorizar e avaliar o desempenho
          e o comportamento profissional dos seus colaboradores e,
          simultaneamente, potenciar um feedback claro e frequente que sustente e
          encoraje a melhoria contínua e o desenvolvimento pessoal e profissional
          de cada pessoa.
        </p>
        <p>
          Se conseguiu aceder, isso significa que a sua Organização o/a registou
          como utilizador e que está ativo/a no sistema CIRVALUE.
        </p>
        <p>
          A utilização de sistemas de Gestão e Avaliação do Desempenho nas
          Organizações é legítima no âmbito das relações contratuais de trabalho
          subordinado e não carece de consentimento pessoal, exceto para a
          eventual utilização de categorias especiais de dados, como é o caso da
          sua imagem.
        </p>
        <p>
          Considerando a relevância da informação contida em qualquer sistema de
          Gestão e Avaliação do Desempenho, a informação, incluindo os registos de
          avaliação nos diversos ciclos avaliativos e comentários produzidos, será
          guardada e conservada na base de dados deste sistema enquanto permanecer
          ao serviço da Organização, e durante um período de 13 meses, depois de,
          eventualmente, permanecer desativado/a ininterruptamente no sistema.
          Depois desse período de tempo, toda a informação que lhe diga respeito
          será automaticamente apagada neste sistema.
        </p>
        <p>
          Todos os dados e informação contida na base de dados e relacionados com
          este sistema, estão encriptados, bem como as comunicações associadas.
        </p>
        <p>
          Por outro lado, o sistema proporciona-lhe um acesso personalizado,
          protegido por uma password definida por si e que pode alterar em
          qualquer momento.
        </p>
        <p>
          Os sistemas Cirvalue são disponibilizados à sua Organização de forma que
          sejam geridos sem intervenção de pessoas externas, nomeadamente da parte
          do fabricante ou da entidade que comercializa o sistema.
        </p>
        <p>
          Sugerimos assim que contacte o Administrador Cirvalue ou o/a Delegado/a
          de Proteção de Dados da sua Organização, para obter mais informação
          sobre as permissões definidas, pessoas que possam ter acesso a
          informação que lhe diga respeito e a outros aspetos relacionados com
          privacidade ou proteção de dados pessoais.
        </p>
        <p>
          Obtenha informação de apoio sobre como utilizar o Cirvalue, acedendo, a
          “Saber mais” ou a “Ajuda” no menu principal.
        </p>
  
        <form class="check">
          <div>
            <input type="checkbox" id="accept" class="cursor-pointer" v-model="accept" />
            <div>
              <label for="accept">
                Tomei conhecimento e entendi a informação prestada (obrigatório
                para prosseguir a experiência no Cirvalue)
              </label>
            </div>
          </div>
          <br />
          <div>
            <input type="checkbox" id="photo" class="cursor-pointer" v-model="photo" checked />
            <div>
              <label for="photo">
                Autorizo a utilização da minha fotografia neste ciclo avaliativo,
                como forma de facilitar a minha identificação inequívoca pelos
                restantes utilizadores (facultativo; poderá retirar esta
                autorização a todo o tempo, e a sua imagem deixará de aparecer)
              </label>
            </div>
          </div>
        </form>
        <button class="btn-check" :class="{ disabled: !accept }" @click="Accept()" >
          PROSSEGUIR EXPERIÊNCIA NO KNOLIA
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.page {
  background: linear-gradient(160deg, #002e61, #2361C2);
}

.container {
  margin: 0 auto;
  width: 800px;
  overflow: auto;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 768px;
    /* min-height: 100vh; */
  }

  .policy {
    width: 600px !important;
  }

  .btn-check {
    width: 600px !important;
  }
}

@media only screen and (max-height: 768px) {
  .title {
    letter-spacing: 0.2em;
    font-size: 1.4em;
    margin-bottom: 30px !important;
  }

  .btn-check {
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-height: 650px) {
  .title {
    letter-spacing: 0.15em !important;
    margin-bottom: 10px !important;
  }

  .policy {
    font-size: 1.1em !important;
    letter-spacing: 0.06em !important;
  }

  .check {
    margin: 30px 0px !important;
  }

  .btn-check {
    margin-bottom: 10px !important;
  }
}

.title {
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 30px;
}

.policy {
  margin: 0 auto;
  width: 600px;
  text-align: justify;
  letter-spacing: 0.08em;
  font-size: 1.2em;
}

.check {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.check div {
  display: flex;
  margin-bottom: 10px;
}

.check div div {
  flex: 30;
  margin-left: 10px;
}

.check input {
  width: 25px;
  height: 25px;
  border: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  flex: 1;
}

.check label {
  display: inline-block;
  vertical-align: middle;
}

.btn-check {
  letter-spacing: 0.2em;
  width: 600px;
  margin-bottom: 60px;
}

.disabled {
  pointer-events: none;
  opacity: .4;
}
</style>
