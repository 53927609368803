<script setup>

import { KNOLIA } from "@/knolia";
import { ref, onUpdated, onMounted } from "vue";
import { Gems } from "../gems";
import ActivitiesJobRow from "../components/ActivitiesJobRow.vue";

const jobs = ref([])
const activities = ref([])
const jobActivities = ref([])

const currentJob = ref({name: null, id: null})




async function getjobActivities(job_name, job_id) {
    currentJob.value.name = job_name
    currentJob.value.id = job_id

    const response = await KNOLIA.CallBackEnd('post', 'get-activities-list', { params: { job_id } })
    if(jobActivities.value.length > 0)
        response.data.forEach(elem => {
            const ind = jobActivities.value.findIndex(job_elem => job_elem.id == elem.id)
            Object.assign(elem, {showChildren: jobActivities.value[ind]?.showChildren})
        })

    jobActivities.value = response.data
    SetActivitiesAccess()
}

async function SetActivityToJob(event) {
    if(currentJob.value.id == null)
        return 

    const selectedTasks = event.dataTransfer.getData('tasks')

    await KNOLIA.CallBackEnd('post', `set-operations&get=task-to-job&job_id=${currentJob.value.id}`, {
        tasks: selectedTasks
    })
     
    getjobActivities(currentJob.value.name, currentJob.value.id)
}

function RemoveTask(index) {
    jobActivities.value.splice(index, 1)
    SetActivitiesAccess()
}

function SetActivitiesAccess() {
    activities.value.forEach(activity => {
        document.getElementById(`activity${activity.id}`).classList.remove('unavailable')
    })

    jobActivities.value.forEach(activity => {
        const ind = activities.value.findIndex(elem => elem.id == activity.id)
        activities.value[ind].showChildren = false

        document.getElementById(`activity${activity.id}`).classList.add('unavailable')
    })
}

onMounted(async () => {
    const responseActivities = await KNOLIA.CallBackEnd('post', 'get-activities-list')
    activities.value = responseActivities.data

    const responseFunctions = await KNOLIA.CallBackEnd('get', 'get-operations', {
        params: {
            get: 'functions'
        }
    })
    jobs.value = responseFunctions.data

})

onUpdated(() => {
    jobActivities.value.forEach((elem, i) => {
        const removeIcon = document.getElementById(`remove-task-${i}`)

        if(removeIcon)
            Gems.Tooltip(removeIcon, 'Remover atividade da função', 'tooltip')
    })
})

</script>

<template>
    <div class="splitter page-container">
        <div id="first" class="top">
                <div id="div-activities-container">
                    <div id="div-activities-title">
                        <span class="">ATIVIDADES</span>
                    </div>
        
                    <div id="div-activities" class="div-column-container scrollbar">
                        <ActivitiesJobRow :activities="activities" :current-job="currentJob" :draggable="true" @remove-task="RemoveTask($event)"/>
                    </div>
                </div>
        </div>

        <div id="second" class="bottom">
            <div id="div-container">
                <div id="div-jobs-container">
                    <div id="div-jobs-title">
                        <h2>Funções</h2>
                    </div>
                    
                    <div id="div-jobs" class="div-column-container scrollbar">
                        <div class="div-job div-row" :class="{'bg-alt-line': currentJob.id == job.func_id}"
                            v-for="job in jobs" :key="job.func_id" @click="getjobActivities(job.func_desc, job.func_id)">
                            <span class="line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{ job.func_desc }}</span>
                            <i class="fas" :class="{'fa-chevron-right': currentJob.id == job.func_id}"></i>
                        </div>
                    </div>
                </div>

                <div id="div-job-tasks-container" @dragenter.prevent @dragover.prevent @drop="SetActivityToJob($event)">
                    <div id="div-job-tasks-title">
                        <h2>Atividades [{{ currentJob.name ?? 'Escolha uma função' }}]</h2>
                    </div>

                    <div id="div-job-tasks" class="div-column-container scrollbar">
                        <ActivitiesJobRow :activities="jobActivities" :current-job="currentJob" :draggable="false" @remove-task="RemoveTask($event)" @get-job-activities="getjobActivities(currentJob.name, currentJob.id)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.top, .bottom {
    overflow-y: visible;
}

#div-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#div-activities-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#div-activities-container,
#div-jobs-container,
#div-job-tasks-container {
    display: flex;
    flex-direction: column;
}

#div-jobs-container,
#div-job-tasks-container {
    text-align: center;
    width: 49%;
}

#div-activities-title {
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 1%;
}

.div-column-container {
    border: 1px solid;
    height: 100%;
    text-align: center;
    padding: 6px;
}

.div-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.div-row {
    padding: 5px 15px;
    cursor: pointer;
    align-items: center;
    border-radius: 25px;
}

.div-row:hover {
    background-color: var(--default-app-alt-line-color);
}

.div-activity {
    border: 1px solid;
}

.div-row:not(:first-child) {
    margin-top: 8px;
}

</style>