<script setup>
import { onMounted, onUpdated, ref } from 'vue';
import { KNOLIA } from '@/knolia';
import { Gems } from '@/gems';
import { useRouter } from "vue-router"

const savedLists = ref([])
const usersList = ref([])
const router = useRouter()


onMounted(() => {
    GetSavedLists()
})

onUpdated(() => {
    savedLists.value.forEach(list => {
        let row = document.getElementById(`list-${list.id}`)
        let removeIcon = document.getElementById(`remove-${list.id}`)
        
        if(row)
            Gems.Tooltip(row, list.criterias, 'tooltip')

        if(removeIcon)
            Gems.Tooltip(removeIcon, 'Remover lista', 'tooltip')
    })
    
    usersList.value.forEach(user => {
        let row = document.getElementById(`user-${user.id}`)
        
        if(row)
            Gems.Tooltip(row, 'Ver cédula de competências', 'tooltip')
    })
})

async function GetSavedLists() {
    const response = await KNOLIA.CallBackEnd('get', 'get-operations', {
        params: {
            get: "saved-lists",
        }
    })

    savedLists.value = response.data
}

async function GetUsers(list) {
    const response = await KNOLIA.CallBackEnd('get', 'get-operations', {
        params: {
            get: "history-users",
            listId: list.id
        }
    })

    usersList.value = response.data
}

async function RemoveFromHistory(id){
    // await KNOLIA.CallBackEnd('get', 'remove-from-history', {
    //     params: {
    //         id,
    //     }
    // })

    savedLists.value = savedLists.value.filter(elem => elem.id != id)
}

function ToSkillCard(user) {
    KNOLIA.SetRequestedUserId(user.id)
    router.push('/skill-card')
}


</script>


<template>

    <div class="div-container">
        <div id="div-content">
            <div class="div-left">
                <span class="group-name">Listas guardadas</span>
            </div>
            <div class="div-right">
                <span class="group-name">Pessoas</span>
            </div>
        </div>

        <div id="div-content">
            <div class="scrollbar div-left list">
                <div class="div-list cursor-pointer" :class="{'bg-alt-line': i % 2 == 0}" 
                    v-for="(list, i) in savedLists" :key="list.id">
                    <div @click="GetUsers(list)" :id="`list-${list.id}`">
                        <span>{{ list.date }}</span>
                        <span>{{ list.designation }}</span>
                        <span></span>
                    </div>
                    <span>
                        <i class="fas fa-trash" @click="RemoveFromHistory(list.id)" :id="`remove-${list.id}`"></i>
                    </span>
                </div>
            </div>

            <div class="scrollbar div-right list">
                <div v-for="(user, i) in usersList" :key="user.id" class="div-user cursor-pointer" @click="ToSkillCard(user)"
                    :class="{'bg-alt-line': i % 2 == 0}" :id="`user-${user.id}`">
                    <span>{{ user.name }}</span>
                    <i class="fas fa-address-card"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.div-container {
    margin-top: 1%;
}

.div-left,
.div-right {
    width: 50%;
}

.list {
    border: 1px solid white;
    padding: 10px;
    overflow-y: scroll;
    height: 300px;
}

#div-content {
    display: flex;
    align-items: center;
    gap: 5%;
}


.div-user,
.div-list { 
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-user i {
    font-size: 1.5em;
}

.div-list div {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.group-name {
    font-weight: bold;
}

</style>
